import Splide from '@splidejs/splide';
import '@splidejs/splide/dist/css/themes/splide-default.min.css';

export function quoteSliderScripts() {
    document.addEventListener( 'DOMContentLoaded', function () {
        const blocks = document.querySelectorAll('.quoteSlider__sliders');

        blocks.forEach(block=>{
            new Splide(block, {
                arrows: false,
                type: "loop",
                perPage: 1,
                perMove: 1,
              }).mount();
        })
    })
}
