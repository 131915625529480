const axios = require("axios");
export function navInit() {
  jQuery(document).ready(function ($) {
    //obsługa hamburgera i elementów menu
    "use strict";

    $('.c-hamburger').on('click', function () {
        ($('.headerMobile__mainMenu').hasClass("is-active")) ? $('.headerMobile__mainMenu').removeClass("is-active"): $('.headerMobile__mainMenu').addClass("is-active");
        ($('.c-hamburger').hasClass("is-active")) ? $('.c-hamburger').removeClass("is-active"): $('.c-hamburger').addClass("is-active");
    });
  });


  //DESKTOP SEARCH BAR
  const desktopMenu = document.querySelector('.headerDesktop');
  const desktopMenuSearchOpen = desktopMenu.querySelector('.searchboxButton');
  const desktopMenuSearchClose = desktopMenu.querySelector('.closeButton');
  const desktopMenuSearchBox = desktopMenu.querySelector('.headerDesktop__searchBox');
  const desktopMenuSearchBoxResult = desktopMenu.querySelector('#searchResult');

  desktopMenuSearchOpen.addEventListener('click', ()=> {
    desktopMenuSearchBox.classList.add('open');
  })

  desktopMenuSearchClose.addEventListener('click', ()=> {
    desktopMenuSearchBox.classList.remove('open');
    desktopMenuSearchBox.querySelector('#searchInput').value = '';
    desktopMenuSearchBoxResult.innerHTML = '';
  })

  const searchProductDesktop = (keyword) => {
    desktopMenuSearchBoxResult.innerHTML = '';
    desktopMenuSearchBoxResult.innerHTML = '<div class="spinnerBox"><i class="fa-li fa fa-spinner fa-spin"></i></div>';

    axios
      .get(
        `${ajax.url}?action=search_product&keyword=${keyword}`
      )
      .then(response => {
        const data = response.data;
        desktopMenuSearchBoxResult.innerHTML = '';

        data.forEach(product => {
          desktopMenuSearchBoxResult.innerHTML += product.product;
        })
      });
  }

  if(desktopMenuSearchBox){
    const searchInput = desktopMenuSearchBox.querySelector('#searchInput');
    const searchInterval = 500;
    let searchIntervalFlag;

    searchInput.addEventListener('keyup', event => {
      clearTimeout(searchIntervalFlag)
      searchIntervalFlag = setTimeout(startSearch, searchInterval);
    })

    searchInput.addEventListener('keydown', event => {
      clearTimeout(searchIntervalFlag)
    })

    const startSearch = () => {
      searchProductDesktop(searchInput.value);
    }
  }

  //DESKTOP SEARCH BAR
  const mobileMenu = document.querySelector('.headerMobile');
  const mobileMenuSearchOpen = mobileMenu.querySelector('.searchboxButton');
  const mobileMenuSearchClose = mobileMenu.querySelector('.closeButton');
  const mobileMenuSearchBox = mobileMenu.querySelector('.headerMobile__searchBox');
  const mobileMenuSearchBoxResult = mobileMenu.querySelector('#searchResult');

  mobileMenuSearchOpen.addEventListener('click', ()=> {
    mobileMenuSearchBox.classList.add('open');
  })

  mobileMenuSearchClose.addEventListener('click', ()=> {
    mobileMenuSearchBox.classList.remove('open');
    mobileMenuSearchBox.querySelector('#searchInput').value = '';
    mobileMenuSearchBoxResult.innerHTML = '';
  })

  const searchProductMobile = (keyword) => {
    mobileMenuSearchBoxResult.innerHTML = '';
    mobileMenuSearchBoxResult.innerHTML = '<div class="spinnerBox"><i class="fa-li fa fa-spinner fa-spin"></i></div>';

    axios
      .get(
        `${ajax.url}?action=search_product&keyword=${keyword}`
      )
      .then(response => {
        const data = response.data;
        mobileMenuSearchBoxResult.innerHTML = '';

        data.forEach(product => {
          mobileMenuSearchBoxResult.innerHTML += product.product;
        })
      });
  }

  if(mobileMenuSearchBox){
    const searchInput = mobileMenuSearchBox.querySelector('#searchInput');
    const searchInterval = 500;
    let searchIntervalFlag;

    searchInput.addEventListener('keyup', event => {
      clearTimeout(searchIntervalFlag)
      searchIntervalFlag = setTimeout(startSearch, searchInterval);
    })

    searchInput.addEventListener('keydown', event => {
      clearTimeout(searchIntervalFlag)
    })

    const startSearch = () => {
      searchProductMobile(searchInput.value);
    }
  }




  const elements = document.querySelectorAll('.menu_mobile li.menu-item-has-children');

  elements.forEach(element => {
    const item = element.querySelector('a');
    item.appendChild(document.createElement("span"));

    item.querySelector('span').addEventListener("click", (e)=>{
      e.preventDefault();
      if(item.classList.contains('open')) {
        item.classList.remove('open');
        element.querySelector('.sub-menu').classList.remove('open');
      } else {
        item.classList.add('open');
        element.querySelector('.sub-menu').classList.add('open');
      }
    })
  })
}
