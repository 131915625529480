import Splide from '@splidejs/splide';
import '@splidejs/splide/dist/css/themes/splide-default.min.css';

export function sliderBeforeAfterScript() {
    document.addEventListener( 'DOMContentLoaded', function () {
        const blocks = document.querySelectorAll('.sliderBeforeAfter__slider');

        if(blocks){
            blocks.forEach(block=>{
                new Splide(block, {
                    type: "loop",
                    arrows: true,
                    pagination: false,
                    gap: 24,
                    perPage: 2,
                    perMove: 1,
                    breakpoints: {
                        768: {
                            perPage: 1,
                            gap: 8
                        }
                    }
                }).mount();
            })
        }
    })
}
