import Splide from '@splidejs/splide';
import '@splidejs/splide/dist/css/themes/splide-default.min.css';

export function ingredientDropdownSliderScript() {
    document.addEventListener( 'DOMContentLoaded', function () {
        const blocks = document.querySelectorAll('.blockIngredientsDropdownSlider__slider');
        const dropdowns = document.querySelectorAll('.blockIngredientsDropdownSlider__dropdown');
        const modals = document.querySelectorAll('.blockIngredientsDropdownSlider__modal');
        const overlay = document.querySelector('.modals__overlay');

        if(blocks){
            blocks.forEach(block=>{
                let slider = new Splide(block, {
                    type: "slide",
                    arrows: true,
                    pagination: false,
                    gap: 24,
                    fixedWidth: 280,
                    perMove: 1,
                    breakpoints: {
                        992: {
                            fixedWidth: 310
                        },
                        576: {
                            fixedWidth: 290,
                            gap: 8
                        },
                        380: {
                            fixedWidth: 250,
                            gap: 8
                        }
                    }
                }).mount();

                let modalOpener = block.querySelectorAll('span.more');
                if(modalOpener){
                    modalOpener.forEach(opener => {
                        opener.addEventListener('click', e => {
                            let element = e.target;
                            let dataTarget = element.getAttribute('data-target');

                            let modal = document.querySelector(`.blockIngredientsDropdownSlider__modal[data-target=${dataTarget}]`);
                            modal.classList.add('active');
                            overlay.classList.add('active');
                        })
                    })
                }
            })

            if(dropdowns){
                dropdowns.forEach(item => {
                    let opener = item.querySelector('h4.opener');
                    opener.addEventListener('click', () => {
                        item.classList.contains('active') ? item.classList.remove('active') : item.classList.add('active');
                    })
                })
            }

            if(modals){
                modals.forEach(item => {
                    item.querySelector('.modal__close').addEventListener('click', () => {
                        item.classList.remove('active');
                        overlay.classList.remove('active');
                    })
                })
            }
        }
    })
}
