import Splide from '@splidejs/splide';
import '@splidejs/splide/dist/css/themes/splide-default.min.css';

export function mainBannerScripts() {
    document.addEventListener( 'DOMContentLoaded', function () {
        const blocks = document.querySelectorAll('.heroBanner__sliders');

        blocks.forEach(block=>{
            var slider = new Splide(block, {
                type: "loop",
                arrows: false,
                pagination: false,
                perPage: 1,
                perMove: 1,
              });

            slider.on('mounted', () => {
                if(slider.length <= slider.options.perPage && slider.options.drag === true) {
                    slider.options = { arrows: false, pagination: false, drag: false };
                    slider.destroy();
                }
            })

            slider.mount();
        })
    })
}
