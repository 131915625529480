import Splide from '@splidejs/splide';
import '@splidejs/splide/dist/css/themes/splide-default.min.css';

export function productCustomGallery() {
  document.addEventListener( 'DOMContentLoaded', function () {
    let secondarySlider = new Splide( '#secondary-slider', {
      fixedWidth : 72,
      fixedHeight: 72,
      height     : 456,
      gap        : 24,
      perPage    : 4,
      perMove    : 1,
      direction  : 'ttb',
      rewind     : true,
      cover      : true,
      isNavigation: true,
      pagination : false,
      arrows: false,
      breakpoints: {
        992: {
          arrows: true,
          direction  : 'ltr',
          height     : 'auto',
        }
      }
    } );

    // secondarySlider.on('mounted', ()=>{
    //   if(secondarySlider.length <= secondarySlider.options.perPage ) {
    //     secondarySlider.options = { arrows: false, pagination: false, drag: false };
    //     secondarySlider.destroy();
    //     secondarySlider.mount();
    //   }
    // })

    secondarySlider.mount();

    var primarySlider = new Splide( '#primary-slider', {
      type       : 'loop',
      pagination : false,
      arrows     : true,
    } );

    primarySlider.sync( secondarySlider ).mount();
  });
}

export function productSummaryDropdown() {
  const dropdowns = document.querySelectorAll('.customProductPage__summaryWrapper--dropdown');
  if(dropdowns){
    dropdowns.forEach(item => {
      let trigger = item.querySelector('h4');
      if(trigger){
        trigger.addEventListener('click', () => {
          item.classList.toggle('opened');
        })
      }
    })
  }
}
