export const customArchiveProductScripts = () => {
  const filterModalOpener = document.querySelector('span.customArchiveProduct__opener');
  const filterModal = document.querySelector('.customArchiveProduct__sidebar');

  if(filterModalOpener && filterModal){
      filterModalOpener.addEventListener('click', () => {
          filterModal.classList.add('opened');
      })
      let modalCloseButton = filterModal.querySelector('span.modal_close');

      if(modalCloseButton){
          modalCloseButton.addEventListener('click', () => {
              filterModal.classList.remove('opened')
          })
      }
  }
}
